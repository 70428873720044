// en_US.js
const en_US = {
    // homepage
    homepage_title: 'Desktop calendar mobile version download',
    homepage_slogn: 'Desktop calendar mobile version',
    homepage_ios_download: 'AppStore review...',
    homepage_download: 'Download',
    homepage_url_calendar: 'https://image.idesktopcal.com/homepage/calendar_normal_usa.png',
    homepage_url_list: 'https://image.idesktopcal.com/homepage/list_normal_usa.png',
    homepage_url_color: 'https://image.idesktopcal.com/homepage/color_normal_usa.png',



    // Support
    support_title: 'DesktopCal Technical Support',
    support_content: 'The DesktopCal iPhone version can be synchronized in real time with the DesktopCal PC version. Can help you easily manage appointments, to-do items, schedules, etc.',
    support_email: 'Email: lw@desktopcal.com',
    support_tel: 'Tel: +86 173465145494',

    // upgrade
    upgradeslogn: 'Upgrade VIP enjoy data sync',
    slogntxt1: 'Real-time synchronization of your data, to avoid the loss of data due to forgetting synchronization.',
    slogntxt2: 'Real-time data synchronization between mobile phones and PCs.',
    slogntxt3: 'You can at home, company, anywhere, more than one computer between the real-time synchronization data.',
    slogntxt4: 'The synchronization of data encryption transmission, privacy protection, more secure.',
    slogntxt5: 'Many people work together, many people can share a VIP account.',
    slogntxt6: 'Ad-free mode.',
    iostips: 'Please set it in the PC DesktopCal',
    wechatpay: 'WeChat payment',
    appleypay: 'Apple Pay',
    paypalpay: 'PayPal',
    upgradenow: 'Upgrade VIP',
    threemonth: '3 months',
    oneyear: '1 year',
    threadyears: '3 years',
    oneyeardiscount: ' ',
    threeyearsdiscount: ' ',
    //... ...


    //agreement
    agreement_license: 'License',
    agreement_welcome: 'Welcome to DesktopCal software and services',
    agreement_Introduction: 'The DesktopCal (hereinafter referred to as the Software) respects and protects the privacy of all users who use the Service. In order to provide you with more accurate and personalized services, the Software will use and disclose your personal information in accordance with this Privacy Policy. However, the software will treat this information with a high degree of diligence and prudence. Except as otherwise provided in this Privacy Policy, the Software will not disclose or provide such information to third parties without your prior permission. By agreeing to the Software Services Use Agreement, you are deemed to have agreed to the entire contents of this Privacy Policy. This Privacy Policy is an integral part of the Software Service Use Agreement.',
    agreement_content1: '1. About your personal information',
    agreement_content11: '1.1 When we provide services, we may collect, store and use relevant personal information that you provide to us when you register for an account or use our services, such as an email box.',
    agreement_content12: '1.2 The shared information that you provide to other parties through our services and the information that you store when you use our services. If you do not provide relevant information, you may not be able to register as a user or enjoy some of the services we offer, or you may not be able to achieve the desired results of the service.',
    agreement_content13: '1.3 This software strictly protects the security of your personal information. We use a variety of security technologies and procedures to protect your personal information from unauthorized access, use or disclosure.',
    agreement_content14: '1.4 This software does not share this information with third parties without your permission.',
    agreement_content2: '2. On information disclosure',
    agreement_content21: '2.1 This software does not disclose your information to untrusted third parties.',
    agreement_content22: '2.2 Disclosure to third parties or administrative or judicial authorities in accordance with the relevant provisions of the law or the requirements of the administrative or judicial authorities.',
    agreement_content23: '2.3 If you have violated relevant national laws, regulations or relevant rules, you need to disclose to third parties.',
    agreement_content3: '3. On information security',
    agreement_content31: '3.1 The software will maintain the confidentiality of the information collected. We better protect the security and confidentiality of the above information by limiting the scope of employees who obtain and use the above information and establishing good internal mechanisms',
    agreement_content4: '4. on the disclaimer',
    agreement_content41: "4.1 The leakage of any personal information caused by your notification of the user's password or sharing the registered account with others, or the leakage of other personal information that is not due to the company's reasons.",
    agreement_content42: "4.2 Any dispute arising from the use of your personal information by any third party as set forth in the company's various terms of service and statements.",
    agreement_content43: '4.3 Any temporary closure due to hacking, computer virus intrusion or government regulation.',
    agreement_content44: '4.4 Any consequences due to force majeure.',
    agreement_content5: '5. Privacy Policy',
    agreement_content51: '5.1 Modifications We may modify the Privacy Policy at any time and reserve the right of final interpretation.',
    agreement_content6: '6. Contact us',
    agreement_content61: '6.1 If you have any questions or concerns regarding this Privacy Policy, please send an email to：service@desktopcal.com',

    uipage_private_title: 'DesktopCal',
    uipage_private_policy: 'Privacy Policy',
    uipage_private_policy_welcome: 'Protecting your personal privacy is regarded as one of our most important tasks. Before you use our software, please read the following statement carefully.',
    uipage_private_policy_welcome1: '"The DesktopCal (hereinafter referred to as the Software) in order to provide you with more personalized services, the software may submit your personal information to our data server in the manner mentioned below. However, please rest assured that we will handle this information with the highest security standards. Without your permission, we will never disclose this information, let alone provide it to any third party.',
    uipage_private_policy_content1: 'Privacy protection',
    uipage_private_policy_content11: 'Respect for the privacy of users is our consistent principle. Unless required by the court or government, we will protect user privacy from disclosure to any third party and our partners. In order to improve the technical level, user experience and service standards of our software, this software may collect and use some non-personal information, or provide some non-personal information to third-party partners for use.',
    uipage_private_policy_content2: 'Collect message',
    uipage_private_policy_content21: 'When you accept our terms of service and voluntarily use our services, the software may collect your personal information, including: your name, language, country, e-mail, user ID, date of birth, And what features of the desktop calendar you use. The information mentioned above will be collected by us in your registration process and user preferences page. We will use this information to contact you and may send you specific service information, such as notifications, technical service events, and security reminders . We occasionally send some product surveys and product promotion information. You download, install and use our software will be deemed to have agreed to our use of your personal information.',
    uipage_private_policy_content3: 'Synchronous Data',
    uipage_private_policy_content31: 'For your personal synchronization data, we will attach great importance to privacy protection. We only store data on the server as data, save historical data for you, and do not do any other purposes. If you need to delete your synchronized data, please email us and we will delete your data.',
    uipage_private_policy_content4:'User account deletion',
    uipage_private_policy_content41:'You can apply for cancellation of your account through customer service (send an email to service@xiaoweiyunfu.com). ',
    uipage_private_policy_content42:'When you log out of your account, you will no longer be able to log in and use our products and services with that account. The content, information, data, records, etc. under the account will be deleted or anonymized. After the account is cancelled, it cannot be restored. ',
    uipage_private_policy_content5: 'Developer',
    uipage_private_policy_content51: 'Beijing Xiaowei Cloud Inc.',
    uipage_private_policy_aboutus: 'Customer Support',
    uipage_private_policy_aboutus1: 'If you have any comments and suggestions, please email us',
    uipage_private_policy_copyright: '',

    // 手机下载
    phonedownload_title: 'DesktopCal Mobile Download',
    phonedownload_slogan: 'The mobile version can be synchronized with the PC version in real time. Record trivia anytime, anywhere.',
    phonedownload_screenshot: 'Phone snapshot',
    phodedownload_desktopcal: 'DesktopCal PC Version',
    phodedownload_about: 'Contact us',
    phodedownload_copyright: ' ',
    phodedownload_url_calendar: 'https://image.idesktopcal.com/phonedownload/calendar_normal_usa.png',
    phodedownload_url_list: 'https://image.idesktopcal.com/phonedownload/list_normal_usa.png',
    phodedownload_url_color: 'https://image.idesktopcal.com/phonedownload/color_normal_usa.png',
}
export default en_US;