import React, { Component } from "react";
import { FormattedMessage, injectIntl } from 'react-intl'

import downloadQrcode from "./res/phonedownload/download_qrcode.png"



import "./PhoneDownload.css";

class PhoneDownload extends Component {

    constructor(props) {
        super(props);

    }


    componentDidMount() {
        this.onclickDownloadAndrod = this.onclickDownloadAndrod.bind(this);
    }

    onclickDownloadAndrod(){
        window.location.href = 'https://download.xdiarys.com/app-dkcweb-universal-release.apk';
    }

    onclickDownloadiOS(){
        window.location.href = 'https://apps.apple.com/cn/app/CalendarTask/id1570563588#?platform=iphone';
    }

    render() {

        const { intl } = this.props;
        let calenar_image = intl.formatMessage({ id: 'phodedownload_url_calendar' });
        let list_image = intl.formatMessage({ id: 'phodedownload_url_list' });
        let color_image = intl.formatMessage({ id: 'phodedownload_url_color' });
        
        return (

            <div class="pdcontainer" >
                <div class="containertitle">
                    <div class="titlebar" >
                        <img src={downloadQrcode} alt="download Qrcode" class="downloadqrcode" />
                        <div class="titlecontainer" >
                            <div class="dltitle"><FormattedMessage id='phonedownload_title' /></div>
                            <div class="dlslogn"><FormattedMessage id='phonedownload_slogan' /></div>
                            <div class="dlbcontainer">
                                <div alt="download ios" class="doloadbtnios" onClick={this.onclickDownloadiOS} />
                                <div alt="download android" class="doloadbtnandroid" onClick={this.onclickDownloadAndrod} />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container2">
                    <div class="screentext"><FormattedMessage id='phonedownload_screenshot' /></div>
                    <div class="imagescontainer">
                        <img src={calenar_image} alt="download android" class="imagelist" />
                        <img src={list_image} alt="download ios" class="imagelist" />
                        <img src={color_image} alt="download android" class="imagelist" />
                    </div>
                </div>

                <div class="bottomcontainer">
                    <div class="aboutcontainer">
                        <a class="desktopcal" href={'http://www.desktopcal.com'} target="_blank" ><FormattedMessage id='phodedownload_desktopcal' /></a>
                        <div class="splittext">
                            |
                            </div>
                        <div class="abouttext">
                            <FormattedMessage id='phodedownload_about' />&nbsp;&nbsp;service@desktopcal.com
                        </div>
                    </div>
                    <div class="copyrighttext">
                        Copyright © Beijing Xiaowei Cloud Service Technology Co., Ltd.&nbsp;&nbsp;&nbsp;&nbsp;<FormattedMessage id='phodedownload_copyright' />
                    </div>
                </div>
            </div>
        )
    }
}



export default injectIntl(PhoneDownload);