const zh_CN = {
    // homepage
    homepage_title: '桌面日历手机版下载',
    homepage_slogn: '桌面日历手机版本',
    homepage_ios_download: 'AppStore审核中...',
    homepage_download: '立即下载',
    homepage_url_calendar: 'https://image.idesktopcal.com/homepage/calendar_normal_chs.png',
    homepage_url_list: 'https://image.idesktopcal.com/homepage/list_normal_chs.png',
    homepage_url_color: 'https://image.idesktopcal.com/homepage/color_normal_chs.png',


    // Support
    support_title: '桌面日历-云端同步笔记技术支持',
    support_content: '桌面日历iPhone版本可以与桌面日历PC版本实时同步。可以帮助您轻松管理约会，待办事项，日程安排等。',
    support_email: '电子邮箱: lw@desktopcal.com',
    support_tel: '电话: +86 173465145494',

    // upgrade
    upgradeslogn: '升级VIP，尊享实时数据同步',
    slogntxt1: '即时同步您的数据，避免因忘记同步造成数据丢失。',
    slogntxt2: '可以手机和PC电脑之间即时同步数据',
    slogntxt3: '可在家里、公司、任何地方，多台电脑之间即时同步数据。',
    slogntxt4: '同步的数据进行加密传输，保护隐私，更安全。',
    slogntxt5: '多人协同工作，多人可以共享一个VIP帐号。',
    slogntxt6: '无广告模式。',
    iostips: '请在电脑版桌面日历里设置',
    wechatpay: '微信支付',
    appleypay: '苹果支付',
    paypalpay: 'PayPal',
    upgradenow: '立即升级VIP',
    threemonth: '3个月',
    oneyear: '1年',
    threadyears: '3年',
    oneyeardiscount: '(9折)',
    threeyearsdiscount: '(7.8折)',
    //... ...


    //agreement
    agreement_license: '使用许可',
    agreement_welcome: '欢迎使用桌面日历软件及服务',
    agreement_Introduction: '《桌面日历-云端同步笔记》（以下简称本软件）尊重并保护所有使用服务用户的个人隐私权。为了给您提供更准确、更有个性化的服务，本软件会按照本隐私权政策的规定使用和披露您的个人信息。但本软件将以高度的勤勉、审慎义务对待这些信息。除本隐私权政策另有规定外，在未征得您事先许可的情况下，本软件不会将这些信息对外披露或向第三方提供。您在同意本软件服务使用协议之时，即视为您已经同意本隐私权政策全部内容。本隐私权政策属于本软件服务使用协议不可分割的一部分。',
    agreement_content1: '一、关于您的个人信息',
    agreement_content11: '1.1 我们提供服务时，可能会收集、储存和使用您在注册账户或使用我们的服务时，向我们提供的相关个人信息，例如邮箱。',
    agreement_content12: '1.2 您通过我们的服务向其他方提供的共享信息，以及您使用我们的服务时所储存的信息。如果您不提供相关信息，可能无法注册成为我们的用户或无法享受我们提供的某些服务，或者无法达到相关服务拟达到的效果。',
    agreement_content13: '1.3 本软件严格保护您个人信息的安全。我们使用各种安全技术和程序来保护您的个人信息不被未经授权的访问、使用或泄漏。',
    agreement_content14: '1.4 本软件不会未经您的允许将这些信息与第三方共享。',
    agreement_content2: '二、关于信息披露',
    agreement_content21: '2.1 本软件不会将您的信息披露给不受信任的第三方。',
    agreement_content22: '2.2 根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露。',
    agreement_content23: '2.3 如您出现违反中国有关法律、法规或者相关规则的情况，需要向第三方披露。',
    agreement_content3: '三、关于信息安全',
    agreement_content31: '3.1 本软件将维护所采集的信息的保密性。我们通过限制获得和使用上述信息的员工范围，建立良好的内部机制来更好的保护上述信息的安全性和保密性。',
    agreement_content4: '四、关于免责说明',
    agreement_content41: "4.1 由于您将用户密码告知他人或与他人共享注册帐户，由此导致的任何个人信息的泄漏，或其他非因本公司原因导致的个人信息的泄漏。",
    agreement_content42: "4.2 任何第三方根据本公司各服务条款及声明中所列明的情况使用您的个人信息，由此所产生的纠纷。",
    agreement_content43: '4.3 任何由于黑客攻击、电脑病毒侵入或政府管制而造成的暂时性关闭。',
    agreement_content44: '4.4 因不可抗力导致的任何后果。',
    agreement_content5: '五、隐私政策',
    agreement_content51: '5.1 修改我们可能会对隐私条款进行随时修改，并保留最终解释权。',
    agreement_content6: '六、联系我们',
    agreement_content61: '6.1 如果您有关于本隐私政策的任何问题或疑虑，请发送邮件到：service@xiaoweiyunfu.com',


    uipage_private_title: '桌面日历',
    uipage_private_policy: '隐私条款',
    uipage_private_policy_welcome: '保护您的个人隐私视为我们最重要的工作之一。在您使用我们的软件之前，请您务必认真阅读以下声明。',
    uipage_private_policy_welcome1: '《桌面日历》（以下简称本软件）为了给您提供更为个性化的服务，本软件可能会以以下提到的方式，将您的个人信息提交至我们的数据服务器。然而，请您放心，我们会以最高的安全标准处理这些信息。没有您的允许，我们绝不会将这些信息公开，更不会提供给任何第三方。',
    uipage_private_policy_content1: '隐私保护',
    uipage_private_policy_content11: '尊重用户的隐私是我们一贯的原则。非法院或者政府要求的情况下，我们将保护用户隐私不向任何第三方以及我们的合作伙伴公开。为了提升我们软件的技术水平、用户体验和服务标准，本软件可能会收集和使用部分非个人的信息，或者将部分非个人信息提供给第三方合作伙伴使用。',
    uipage_private_policy_content2: '信息收集',
    uipage_private_policy_content21: '当您接受我们的服务条款并自愿使用我们的服务时，本软件可能会收集您的个人信息，这些信息包括：您的姓名、使用何种语言、国家、e-mail、用户ID、出生日期，以及您使用桌面日历的哪些功能。 上面提及到的信息将在您注册流程和用户偏好设置页面被我们收集，我们将用这些信息与您取得联系，并且可能给您发送特定的服务信息，例如通知、技术的服务事件、安全提醒。我们偶尔也会发送一些产品调查、产品促销信息。 您下载，安装并使用我们的软件将被视为已经同意我们使用您的个人信息。',
    uipage_private_policy_content3: '同步数据',
    uipage_private_policy_content31: '您的个人同步数据，我们会非常重视隐私保护。我们只做为数据存储在服务器上，为您保存历史数据，不做任何的其他用途。如果您需要删除您的同步数据，请发邮件给我们，我们会将您的数据删除。',
    uipage_private_policy_content4: '用户账号注销',
    uipage_private_policy_content41: '您可以通过客服方式（发送邮件到 service@xiaoweiyunfu.com）申请注销您的账号。',
    uipage_private_policy_content42: '当您注销账号后，您将无法再以该账号登录和使用我们的产品与服务。该账号下的内容、信息、数据、记录等将会被删除或匿名化处理（但法律法规另有规定或监管部门另有要求的除外，如依据《中华人民共和国网络安全法》规定，您的网络操作日志将至少保留六个月的时间）。账号注销完成后，将无法恢复。',
    uipage_private_policy_content5: '开发者',
    uipage_private_policy_content51: '北京小微云服科技有限公司',
    uipage_private_policy_aboutus: '客户支持',
    uipage_private_policy_aboutus1: '如果您有任何意见和建议，敬请邮件联系我们',
    uipage_private_policy_copyright: '京ICP备18016017号-2',

    // 手机下载
    phonedownload_title: '桌面日历手机版下载',
    phonedownload_slogan: '手机版桌面日历可以和电脑版桌面日历实时同步随时随地记录琐事',
    phonedownload_screenshot: '屏幕快照',
    phodedownload_desktopcal: '桌面日历电脑版',
    phodedownload_about: '联系我们',
    phodedownload_copyright: '京ICP备18016017号-2',
    phodedownload_url_calendar: 'https://image.idesktopcal.com/phonedownload/calendar_normal_chs.png',
    phodedownload_url_list: 'https://image.idesktopcal.com/phonedownload/list_normal_chs.png',
    phodedownload_url_color: 'https://image.idesktopcal.com/phonedownload/color_normal_chs.png',

}
export default zh_CN;