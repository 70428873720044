import React, { Component } from "react";
import { FormattedMessage, injectIntl } from 'react-intl'

import "./PrivacyPolicy.css";


class PrivacyPolicy extends Component {

    constructor(props) {
        super(props);
    }

    render() {



        return (
            <div className="web" >
                <div className="header">
                    <div className="content">
                        <div className="left"><FormattedMessage id='uipage_private_title' /></div>
                    </div>
                </div>

                <div className="eula">
                    <div className="title"><FormattedMessage id='uipage_private_policy' /></div>
                    <div className="text"><FormattedMessage id='uipage_private_policy_welcome' /><br />
                        <FormattedMessage id='uipage_private_policy_welcome1' />
                    </div>

                    <div className="part"><FormattedMessage id='uipage_private_policy_content1' /></div>
                    <div className="text">
                        <FormattedMessage id='uipage_private_policy_content11' />
                    </div>

                    <div className="part"><FormattedMessage id='uipage_private_policy_content2' /></div>
                    <div className="text">
                        <FormattedMessage id='uipage_private_policy_content21' />
                    </div>

                    <div className="part"><FormattedMessage id='uipage_private_policy_content3' /></div>
                    <div className="text">
                        <FormattedMessage id='uipage_private_policy_content31' />
                    </div>

                    <div className="part"><FormattedMessage id='uipage_private_policy_content4' /></div>
                    <div className="text">
                        <FormattedMessage id='uipage_private_policy_content41' />
                        <FormattedMessage id='uipage_private_policy_content42' />
                    </div>

                    <div className="part"><FormattedMessage id='uipage_private_policy_content5' /></div>
                    <div className="text">
                        <FormattedMessage id='uipage_private_policy_content51' />
                    </div>

                </div>

                <div className="footer">
                    <div className="content">
                        <div className="center">
                            <div className="left" >
                                <div className="top"><FormattedMessage id='uipage_private_policy_aboutus' /></div>
                                <div className="top1"><FormattedMessage id='uipage_private_policy_aboutus1' /><br></br>service@xiaoweiyunfu.com
                                </div>
                            </div>
                        </div>
                        <div className="bottom">Copyright © 2020 All rights reserved. <a className="link" href='http://beian.miit.gov.cn/'><FormattedMessage id='uipage_private_policy_copyright' /></a></div>
                    </div>
                </div>
            </div >
        )
    }
}


export default injectIntl(PrivacyPolicy);