const ko_Kr = {
    // homepage
    homepage_title: '데스크톱 캘린더 모바일 버전 다운로드',
    homepage_slogn: '데스크톱 캘린더 모바일 버전',
    homepage_ios_download: 'AppStore검토 중...',
    homepage_download: '지금 다운로드',
    homepage_url_calendar: 'https://image.idesktopcal.com/homepage/calendar_normal_kor.png',
    homepage_url_list: 'https://image.idesktopcal.com/homepage/list_normal_kor.png',
    homepage_url_color: 'https://image.idesktopcal.com/homepage/color_normal_kor.png',


    // Support
    support_title: '데스크톱 캘린더',
    support_content: '데스크탑 캘린더 iPhone 버전은 데스크톱 캘린더 PC 버전과 실시간으로 동기화 할 수 있습니다. 약속, 할일, 일정 등을 쉽게 관리 할 수 있습니다.',
    support_email: '이메일 : lw@desktopcal.com',
    support_tel: '전화 번호 : +86 173465145494',

    // upgrade
    upgradeslogn: 'VIP 업그레이드',
    slogntxt1: '귀하의 데이터의 실시간 동기화, 데이터 손실을 피하기 위해.',
    slogntxt2: '휴대폰과 PC 간의 실시간 데이터 동기화.',
    slogntxt3: '가정, 회사, 어디서나 실시간 동기화 데이터를 이용할 수 있지만 데이터를 서로 동기화하기 ,가능하지만 동시에 여러 대의 컴퓨터 실시간 동기화 데이터가 가능합니다.',
    slogntxt4: '데이터 동기화 암호화, 개인 정보 보호 및보다 안전한 동기화.',
    slogntxt5: '많은 사람들이 함께 일하면서 많은 사람들이 VIP 계정을 공유 할 수 있습니다.',
    slogntxt6: '광고없는 모드.',
    iostips: 'PC 데스크톱 캘린더에서 설정',
    wechatpay: 'WeChat 지불',
    appleypay: 'Apple 지불',
    paypalpay: 'PayPal',
    upgradenow: '지금 VIP 업그레이드',
    threemonth: '3 개월',
    oneyear: '1 년',
    threadyears: '3 년',
    oneyeardiscount: ' ',
    threeyearsdiscount: ' ',
    //... ...


    //agreement
    agreement_license: '라이센스',
    agreement_welcome: '데스크톱 캘린더 소프트웨어 및 서비스에 오신 것을 환영합니다',
    agreement_Introduction: '데스크톱 캘린더 (이하, 소프트웨어라고 함)는 본 서비스를 이용하는 모든 사용자의 개인 정보를 존중하고 보호합니다. 보다 정확하고 맞춤화 된 서비스를 제공하기 위해 소프트웨어는 본 개인 정보 취급 방침에 따라 귀하의 개인 정보를 사용하고 공개합니다. 그러나 소프트웨어는 이러한 정보를 높은 수준의 부지런함과 신중함으로 취급합니다. 본 개인 정보 보호 정책에서 달리 규정하지 않는 한, 소프트웨어는 귀하의 사전 허락없이 제 3 자에게 그러한 정보를 공개하거나 제공하지 않습니다. 소프트웨어 서비스 사용 계약에 동의함으로써 귀하는 본 개인 정보 보호 정책의 전체 내용에 동의 한 것으로 간주됩니다. 이 개인 정보 보호 정책은 소프트웨어 서비스 사용 계약의 필수적인 부분입니다.',
    agreement_content1: '1 귀하의 개인 정보에 관하여',
    agreement_content11: '1.1 우리가 서비스를 제공 할 때 우리는 귀하가 계정에 등록하거나 우리의 서비스를 이용할 때 귀하가 제공 한 이메일 주소와 같은 관련 개인 정보를 수집, 저장 및 사용할 수 있습니다.',
    agreement_content12: '1.2 서비스를 통해 다른 당사자에게 제공하는 공유 정보 및 귀하가 당사 서비스를 이용할 때 귀하가 저장하는 정보. 관련 정보를 제공하지 않으면 사용자로 등록하거나 제공하는 서비스 중 일부를 즐길 수 없거나 원하는 서비스 결과를 얻을 수 없을 수도 있습니다.',
    agreement_content13: '1.3 이 소프트웨어는 개인 정보의 보안을 엄격하게 보호합니다. 당사는 무단 액세스, 사용 또는 공개로부터 귀하의 개인 정보를 보호하기 위해 다양한 보안 기술 및 절차를 사용합니다.',
    agreement_content14: '1.4 이 소프트웨어는 귀하의 허락없이이 정보를 제 3 자와 공유하지 않습니다.',
    agreement_content2: '2 정보 공개에 관하여',
    agreement_content21: '2.1 이 소프트웨어는 신뢰할 수없는 제 3 자에게 귀하의 정보를 공개하지 않습니다.',
    agreement_content22: '2.2 법의 관련 조항 또는 행정 또는 사법 당국의 요구 사항에 따라 제 3 자나 행정 또는 사법 기관에 공개.',
    agreement_content23: '2.3 관련 국내법, 규정 또는 관련 규정을 위반 한 경우 제 3 자에게 공개해야합니다.',
    agreement_content3: '3 정보 보안에 관하여',
    agreement_content31: '3.1 소프트웨어는 수집 된 정보의 기밀을 유지합니다. 우리는 위의 정보를 얻고 사용하는 직원의 범위를 제한함으로써 위 정보의 보안 및 기밀성을보다 잘 보호 할 수있는 좋은 내부 메커니즘을 구축합니다.',
    agreement_content4: '4 면책 조항 정보',
    agreement_content41: "4.1 사용자의 비밀번호 통지 또는 등록 된 계정을 다른 사람과 공유하거나 회사의 사유로 인한 것이 아닌 다른 개인 정보를 공개함으로써 야기 된 개인 정보 누설.",
    agreement_content42: "4.2 회사의 다양한 서비스 약관 및 진술에 명시된 조건에 따라 제 3자가 귀하의 개인 정보를 사용함으로써 발생하는 모든 분쟁.",
    agreement_content43: '4.3 해킹, 컴퓨터 바이러스 침입 또는 정부 규제로 인한 일시적인 폐쇄.',
    agreement_content44: '4.4 불가항력으로 야기 된 모든 결과.',
    agreement_content5: '5 개인 정보 보호 정책',
    agreement_content51: '5.1 수정 우리는 언제든지 개인 정보 보호 정책을 변경할 수 있으며 최종 해석 권한을 보유합니다.',
    agreement_content6: '6 문의하기',
    agreement_content61: '6.1 본 개인 정보 보호 정책에 관한 질문이나 우려 사항이 있으시면 service@desktopcal.com으로 전자 메일을 보내주십시오.',

    uipage_private_title: '데스크탑 캘린더',
    uipage_private_policy: '개인 정보 정책',
    uipage_private_policy_welcome: '귀하의 개인 정보를 보호하는 것은 당사의 가장 중요한 업무 중 하나로 간주됩니다. 소프트웨어를 사용하기 전에 다음 내용을주의 깊게 읽으십시오.',
    uipage_private_policy_welcome1: '데스크톱 캘린더 (이하, 소프트웨어라고 함) 보다 개인화 된 서비스를 제공하기 위해 소프트웨어는 아래에 언급 된 방식으로 귀하의 개인 정보를 당사의 데이터 서버에 제출할 수 있습니다. 그러나 우리는 가장 높은 보안 기준으로이 정보를 처리 할 것입니다. 귀하의 허락 없이는이 정보를 제 3 자에게 제공하는 것은 물론 공개하지 않을 것입니다.',
    uipage_private_policy_content1: '개인 정보 보호',
    uipage_private_policy_content11: '사용자의 프라이버시를 존중하는 것이 우리의 일관된 원칙입니다. 법원이나 정부에서 요구하지 않는 한, 당사는 제 3 자 및 당사 파트너에게 공개되지 않도록 사용자 개인 정보를 보호합니다. 소프트웨어의 기술 수준, 사용자 경험 및 서비스 표준을 개선하기 위해이 소프트웨어는 일부 비 개인 정보를 수집 및 사용하거나 사용을 위해 타사 파트너에게 일부 비 개인 정보를 제공 할 수 있습니다.',
    uipage_private_policy_content2: '메시지 수집',
    uipage_private_policy_content21: '귀하가 당사의 서비스 약관에 동의하고 당사 서비스를 자발적으로 사용할 때 소프트웨어는 귀하의 이름, 언어, 국가, 이메일, 사용자 ID, 생년월일, 그리고 사용하는 데스크탑 캘린더의 기능. 위에 언급 된 정보는 귀하의 등록 프로세스 및 사용자 환경 설정 페이지에서 당사에 의해 수집됩니다. 당사는이 정보를 사용하여 귀하에게 연락하고 알림, 기술 서비스 이벤트 및 보안 알림과 같은 특정 서비스 정보를 귀하에게 보낼 수 있습니다. . 때때로 일부 제품 설문 조사 및 제품 프로모션 정보를 보냅니다. 귀하가 당사 소프트웨어를 다운로드, 설치 및 사용하는 것은 귀하의 개인 정보 사용에 동의 한 것으로 간주됩니다.',
    uipage_private_policy_content3: '동기 데이터',
    uipage_private_policy_content31: '개인 동기화 데이터의 경우 개인 정보 보호를 매우 중요하게 생각합니다. 당사는 서버에 데이터를 데이터로만 저장하고 귀하를 위해 과거 데이터를 저장하며 다른 목적은 수행하지 않습니다. 동기화 된 데이터를 삭제해야하는 경우 이메일을 보내 주시면 데이터를 삭제하겠습니다.',
    uipage_private_policy_content4:'사용자 계정 로그아웃',
    uipage_private_policy_content41:'고객 서비스를 통해 계정 해지를 신청할 수 있습니다(service@xiaoweiyunfu.com으로 이메일 보내기). ',
    uipage_private_policy_content42:'계정에서 로그아웃하면 더 이상 해당 계정으로 로그인하여 당사 제품과 서비스를 사용할 수 없습니다. 계정의 콘텐츠, 정보, 데이터, 기록 등은 삭제되거나 익명으로 처리됩니다. 계정이 해지된 후에는 복원할 수 없습니다. ',
    uipage_private_policy_content5: '개발자',
    uipage_private_policy_content51: 'Beijing Xiaowei Cloud Inc.',
    uipage_private_policy_aboutus: '고객 지원',
    uipage_private_policy_aboutus1: '의견이나 제안이 있으시면 이메일을 보내주십시오.',
    uipage_private_policy_copyright: '',

    // 手机下载
    phonedownload_title: '모바일 버전 다운로드',
    phonedownload_slogan: '모바일 버전은 컴퓨터 버전과 실시간으로 동기화되어 언제 어디서나 사소한 것을 기록 할 수 있습니다',
    phonedownload_screenshot: '전화 스냅 샷',
    phodedownload_desktopcal: '데스크톱 캘린더 컴퓨터 버전',
    phodedownload_about: '문의하기',
    phodedownload_copyright: ' ',
    phodedownload_url_calendar: 'https://image.idesktopcal.com/phonedownload/calendar_normal_kor.png',
    phodedownload_url_list: 'https://image.idesktopcal.com/phonedownload/list_normal_kor.png',
    phodedownload_url_color: 'https://image.idesktopcal.com/phonedownload/color_normal_kor.png',
}
export default ko_Kr;