import React, { Component } from "react";
import { BrowserRouter as BrowserRouter, Route } from "react-router-dom";


import Upgrade from "./Upgrade";
import Agreement from "./Agreement";
import HomePage from "./HomePage";
import Support from "./Support";
import PhoneDownload from "./PhoneDownload";
import PrivacyPolicy from "./PrivacyPolicy";
import 'babel-polyfill';

import { IntlProvider } from 'react-intl'; /* react-intl imports */
import Locale from './lang/Locale'

import ParamStore from 'param-store'
global.Intl = require('intl');


class App extends Component {

    constructor(props) {
        super(props);

    }

    componentDidMount() {

    }

    render() {

        Locale.Instance.InitLang(ParamStore.get('lang'), navigator.language);

        let _locale = Locale.Instance.getLocal();
        let _message = Locale.Instance.getMessage();

        return (
            <IntlProvider locale={_locale} messages={_message} >
                <BrowserRouter>
                    <div>
                        <Route path="/" exact component={HomePage} />
                        <Route path="/upgrade" component={Upgrade} />
                        <Route path="/agreement" component={Agreement} />
                        <Route path="/support" component={Support} />
                        <Route path="/phonedownload" component={PhoneDownload} />
                        <Route path="/privacypolicy" component={PrivacyPolicy} />
                    </div>
                </BrowserRouter>
            </IntlProvider>
        );
    }
}

export default App;
