const zh_TW = {
    // homepage
    homepage_title: '桌面日曆手機版下載',
    homepage_slogn: '桌面日曆手機版本',
    homepage_ios_download: 'AppStore審核中...',
    homepage_download: '立即下載',
    homepage_url_calendar: 'https://image.idesktopcal.com/homepage/calendar_normal_cht.png',
    homepage_url_list: 'https://image.idesktopcal.com/homepage/list_normal_cht.png',
    homepage_url_color: 'https://image.idesktopcal.com/homepage/color_normal_cht.png',


    // Support
    support_title: '桌面日曆',
    support_content: '桌面日曆iPhone版本可以與桌面日曆PC版本實時同步。可以幫助您輕鬆管理約會，待辦事項，日程安排等。',
    support_email: 'Email: lw@desktopcal.com',
    support_tel: 'Tel: +86 173465145494',

    // upgrade
    upgradeslogn: '升級VIP，尊享實時數據同步',
    slogntxt1: '即時同步您的數據，避免因忘記同步造成數據丟失。',
    slogntxt2: '可以手機和PC電腦之間即時同步數據。',
    slogntxt3: '可在家裡、公司、任何地方，多台電腦之間即時同步數據。',
    slogntxt4: '同步的數據進行加密傳輸，保護隱私，更安全。',
    slogntxt5: '多人協同工作，多人可以共享一個VIP帳號。',
    slogntxt6: '無廣告模式。',
    iostips: '請在電腦版桌面日曆裡設置',
    wechatpay: '微信支付',
    appleypay: 'Apple支付',
    paypalpay: 'PayPal',
    upgradenow: '立即升級VIP',
    threemonth: '3個月',
    oneyear: '1年',
    threadyears: '3年',
    oneyeardiscount: '(9折)',
    threeyearsdiscount: '(7.8折)',
    //... ...


    //agreement
    agreement_license: '使用許可',
    agreement_welcome: '歡迎使用桌面日曆軟件及服務',
    agreement_Introduction: '《桌面日曆》（以下簡稱本軟件）尊重並保護所有使用服務用戶的個人隱私權。為了給您提供更準確、更有個性化的服務，本軟件會按照本隱私權政策的規定使用和披露您的個人資訊。但本軟件將以高度的勤勉、審慎義務對待這些資訊。除本隱私權政策另有規定外，在未征得您事先許可的情况下，本軟件不會將這些資訊對外披露或向協力廠商提供。您在同意本軟件服務使用協定之時，即視為您已經同意本隱私權政策全部內容。本隱私權政策屬於本軟件服務使用協定不可分割的一部分。',
    agreement_content1: '一、關於您的個人資訊',
    agreement_content11: '1.1 我們提供服務時，可能會收集、儲存和使用您在注册帳戶或使用我們的服務時，向我們提供的相關個人資訊，例如郵箱。',
    agreement_content12: '1.2 您通過我們的服務向其他方提供的共亯資訊，以及您使用我們的服務時所儲存的資訊。如果您不提供相關資訊，可能無法注册成為我們的用戶或無法享受我們提供的某些服務，或者無法達到相關服務擬達到的效果。',
    agreement_content13: '1.3 本軟件嚴格保護您個人資訊的安全。我們使用各種安全技術和程式來保護您的個人資訊不被未經授權的訪問、使用或洩漏。',
    agreement_content14: '1.4 本軟件不會未經您的允許將這些資訊與協力廠商共亯。',
    agreement_content2: '二、關於資訊披露',
    agreement_content21: '2.1 本軟件不會將您的資訊披露給不受信任的協力廠商。',
    agreement_content22: '2.2 根據法律的有關規定，或者行政或司法機构的要求，向協力廠商或者行政、司法機构披露。',
    agreement_content23: '2.3 如您出現違反國家有關法律、法規或者相關規則的情況，需要向第三方披露。',
    agreement_content3: '三、關於資訊安全',
    agreement_content31: '3.1 本軟件將維護所採集的資訊的保密性。我們通過限制獲得和使用上述資訊的員工範圍，建立良好的內部機制來更好的保護上述資訊的安全性和保密性。',
    agreement_content4: '四、關於免責說明',
    agreement_content41: "4.1 由於您將使用者密碼告知他人或與他人共亯注册帳戶，由此導致的任何個人資訊的洩漏，或其他非因本公司原因導致的個人資訊的洩漏。",
    agreement_content42: "4.2 任何協力廠商根據本公司各服務條款及聲明中所列明的情况使用您的個人資訊，由此所產生的糾紛。",
    agreement_content43: '4.3 任何由於駭客攻擊、電腦病毒侵入或政府管制而造成的暫時性關閉。',
    agreement_content44: '4.4 因不可抗力導致的任何後果。',
    agreement_content5: '五、隱私政策',
    agreement_content51: '5.1 修改我們可能會對隱私條款進行隨時修改，並保留最終解釋權。',
    agreement_content6: '六、聯繫我們',
    agreement_content61: '6.1 如果您有關於本隱私政策的任何問題或疑慮，請發送郵件到：service@desktopcal.com',

    uipage_private_title: '桌面日曆',
    uipage_private_policy: '隱私條款',
    uipage_private_policy_welcome: '保護您的個人隱私視為我們最重要的工作之一。在您使用我們的軟件之前，請您務必認真閱讀以下聲明。',
    uipage_private_policy_welcome1: '《桌面日曆-雲端同步筆記》（以下簡稱本軟件）為了給您提供更為個性化的服務，本軟件可能會以以下提到的方式，將您的個人信息提交至我們的數據服務器。然而，請您放心，我們會以最高的安全標準處理這些信息。沒有您的允許，我們絕不會將這些信息公開，更不會提供給任何第三方。',
    uipage_private_policy_content1: '隱私保護',
    uipage_private_policy_content11: '尊重用戶的隱私是我們一貫的原則。非法院或者政府要求的情況下，我們將保護用戶隱私不向任何第三方以及我們的合作夥伴公開。為了提升我們軟件的技術水平、用戶體驗和服務標準，本軟件可能會收集和使用部分非個人的信息，或者將部分非個人信息提供給第三方合作夥伴使用。',
    uipage_private_policy_content2: '信息收集',
    uipage_private_policy_content21: '當您接受我們的服務條款並自願使用我們的服務時，本軟件可能會收集您的個人信息，這些信息包括：您的姓名、使用何種語言、國家、e-mail、用戶ID、出生日期，以及您使用桌面日曆的哪些功能。上面提及到的信息將在您註冊流程和用戶偏好設置頁面被我們收集，我們將用這些信息與您取得聯繫，並且可能給您發送特定的服務信息，例如通知、技術的服務事件、安全提醒。我們偶爾也會發送一些產品調查、產品促銷信息。您下載，安裝並使用我們的軟件將被視為已經同意我們使用您的個人信息。',
    uipage_private_policy_content3: '同步數據',
    uipage_private_policy_content31: '您的個人同步數據，我們會非常重視隱私保護。我們只做為數據存儲在服務器上，為您保存歷史數據，不做任何的其他用途。如果您需要刪除您的同步數據，請發郵件給我們，我們會將您的數據刪除。',
    uipage_private_policy_content4: '用戶賬號註銷',
    uipage_private_policy_content41: '您可以通過客服方式（發送郵件到 service@xiaoweiyunfu.com）申請註銷您的賬號。 ',
    uipage_private_policy_content42: '當您註銷賬號後，您將無法再以該賬號登錄和使用我們的產品與服務。該賬號下的內容、信息、數據、記錄等將會被刪除或匿名化處理。賬號註銷完成後，將無法恢復。 ',
    uipage_private_policy_content5: '開發者',
    uipage_private_policy_content51: 'Beijing Xiaowei Cloud Inc.',
    uipage_private_policy_aboutus: '客戶支持',
    uipage_private_policy_aboutus1: '如果您有任何意見和建議，敬請郵件聯繫我們',
    uipage_private_policy_copyright: '',

    // 手机下载
    phonedownload_title: '桌面日曆手機版下載',
    phonedownload_slogan: '手機版桌面日曆可以和電腦版桌面日曆實時同步隨時隨地記錄瑣事',
    phonedownload_screenshot: '屏幕快照',
    phodedownload_desktopcal: '桌面日曆電腦版',
    phodedownload_about: '聯繫我們',
    phodedownload_copyright: ' ',
    phodedownload_url_calendar: 'https://image.idesktopcal.com/phonedownload/calendar_normal_cht.png',
    phodedownload_url_list: 'https://image.idesktopcal.com/phonedownload/list_normal_cht.png',
    phodedownload_url_color: 'https://image.idesktopcal.com/phonedownload/color_normal_cht.png',
}


export default zh_TW;