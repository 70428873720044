import acceptLanguage from 'accept-language';
import { addLocaleData } from 'react-intl'; /* react-intl imports */
import en from 'react-intl/locale-data/en';
import zh from 'react-intl/locale-data/zh';
import ko from 'react-intl/locale-data/ko';

import enUS from './en_US';
import zhCN from './zh_CN';
import koKr from './ko_Kr';
import zhTW from './zh_TW';


import queryString from 'query-string';


addLocaleData([...en, ...zh, ...ko]);


const LocalMap = {
    'zh': zhCN,
    'en': enUS,
    'ko': koKr,
    'zh-tw':zhTW,
};


acceptLanguage.languages(['zh', 'en', 'ko', 'zh-tw']);



class Locale{

    static s_instance = null;

    constructor(props) {
    
    }

    static get Instance(){
        if( Locale.s_instance == null ){
            Locale.s_instance = new Locale();
        }
            
        return Locale.s_instance;
    }

    InitLang( lang, navigatorlanguage ){
        if( lang ){
            if( lang === 'chs' ){
                this.locale = 'zh';    
            }
            else if( lang === 'cht' ){
                this.locale = 'zh-tw';
            }
            else if( lang === 'kor' ){
                this.locale = 'ko';
            }
            else{
                this.locale = 'en';
            }
        }
        else
        {
            this.locale = this.detectLocale( navigatorlanguage );
        }    
    }


    getLocal(){
        return this.locale;
    }

    getMessage(){
        return LocalMap[this.locale];
    }

    detectLocale(req) {
        return acceptLanguage.get( req ) || 'en';
    }


}
    

export default Locale;
