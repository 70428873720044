import React, { Component } from "react";
import { FormattedMessage } from 'react-intl'

class Support extends Component {
    

    constructor(props) {
        super(props);
    }


    componentDidMount() {

    }


    render() {

        return(
            <div className="AgreementApp">
                <h2><FormattedMessage id="support_title" /></h2>
                <p><FormattedMessage id="support_content" /></p>
                
                <p><FormattedMessage id="support_email" /></p>
                <p><FormattedMessage id="support_tel" /></p>
                <br />
            </div>

        );
    }
}

export default Support;