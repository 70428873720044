import React, { Component } from "react";

import homepage_title from "./homepage_title.png";
import homepage_logo from "./homepage_logo.png";
import dl_android_icon from "./dl_android_icon.png";
import dl_apple_icon from "./dl_apple_icon.png";
import wechat_open_web from "./wechat_open_web.png";
import { FormattedMessage, injectIntl } from 'react-intl'
import Locale from './lang/Locale'

// https://www.npmjs.com/package/react-scroll-paged-view

class HomePage1 extends Component {

    constructor(props) {
        super(props);

        this.state = {
            iOSWeb: false,
            isWechatWeb: 'none'
        };

        this.onClickDownload = this.onClickDownload.bind(this);
        this.isWeiXin = this.isWeiXin.bind(this);

        const lang = navigator.language;
    }

    componentDidMount() {

        var u = navigator.userAgent;
        //var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1;
        var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
        this.setState({ iOSWeb: isiOS });



        let locale = Locale.Instance.getLocal();
        if (locale === 'zh') {
            if (!isiOS && this.isWeiXin()) {
                this.setState({ isWechatWeb: 'inline' });
            }
        }
    }

    //判断是否是微信浏览器的函数
    isWeiXin() {
        //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
        var ua = navigator.userAgent.toLowerCase();
        //通过正则表达式匹配ua中是否含有MicroMessenger字符串
        if (ua.match(/MicroMessenger/i) === 'micromessenger') {
            return true;
        } else {
            return false;
        }
    }

    onClickDownload() {

    }

    render() {

        var isChs = false;
        let locale = Locale.Instance.getLocal();
        if ( locale === 'zh') {
            isChs = true
        }

        return (

            <div className="homepage-container" >
                <img style={{ display: this.state.isWechatWeb }} src={wechat_open_web} alt="wechat open web" className="wechat-modal" />

                <img src={homepage_title} alt="homepage title" className="homepage-title" />
                <img src={homepage_logo} alt="homepage logo" className="homepage-logo" />
                <div className="homepage-slogn"><FormattedMessage id='homepage_slogn' /></div>

                {this.state.iOSWeb ?

                    <a href="https://apps.apple.com/cn/app/CalendarTask/id1570563588"  className="homepage-dl-android-btn" >
                        <img src={dl_apple_icon} className="dl_icon"></img>
                        <FormattedMessage id='homepage_download' />
                    </a>
                    :
                    <a href="https://download.xdiarys.com/app-dkcweb-universal-release.apk" download="desktopcal_android.apk" className="homepage-dl-android-btn" >
                        <img src={dl_android_icon} className="dl_icon"></img>
                        <FormattedMessage id='homepage_download' />
                    </a>
                }

                { isChs ?
                    <div className="beian">
                        <a className="beianlink" href='http://beian.miit.gov.cn/'>京ICP备18016017号-2</a>
                    </div>
                    :
                    null
                }
            </div>
        )
    }
}




export default injectIntl(HomePage1);
