import React, { Component } from "react";
import ScrollPagedView from 'react-scroll-paged-view'
import { injectIntl } from 'react-intl'
import Locale from './lang/Locale'
import HomePage1 from './HomePage1'


// https://www.npmjs.com/package/react-scroll-paged-view

class HomePage extends Component {

    constructor(props) {
        super(props);
        const lang = navigator.language;


        this.state = {
            iOSWeb: false,
        };
    }

    componentDidMount() {
        var u = navigator.userAgent;
        //var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1;
        var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
        this.setState({ iOSWeb: isiOS });

        const { intl } = this.props;
        document.title = intl.formatMessage({ id: 'homepage_title' });
    }


    render() {

        let locale = Locale.Instance.getLocal();

        
        const { intl } = this.props;
        let calenar_image = intl.formatMessage({ id: 'homepage_url_calendar' });
        let list_image = intl.formatMessage({ id: 'homepage_url_list' });
        let color_image = intl.formatMessage({ id: 'homepage_url_color' });


        return (

            <ScrollPagedView>
                <HomePage1 />
                <div class="divhomepage2">
                    <img src={calenar_image} alt="homepageimage2" class="homepageImage" />
                </div>
                <div class="divhomepage3">
                    <img src={list_image} alt="homepageimage3" class="homepageImage" />
                </div>
                <div class="divhomepage4">
                    <img src={color_image} alt="homepageimage4" class="homepageImage" />
                </div>
            </ScrollPagedView>
        )
    }
}

export default injectIntl(HomePage);