import React, { Component } from "react";
import { FormattedMessage } from 'react-intl'
import "./App.css";


class Agreement extends Component {

    render() {

        return (
            <div className="AgreementApp">
                <h2><FormattedMessage id='agreement_license' /></h2>
                <p><FormattedMessage id='agreement_welcome' /></p>
                <p><FormattedMessage id='agreement_Introduction' /></p>

                <h4><FormattedMessage id='agreement_content1' /></h4>
                <FormattedMessage id='agreement_content11' /><br />
                <FormattedMessage id='agreement_content12' /><br />
                <FormattedMessage id='agreement_content13' /><br />
                <FormattedMessage id='agreement_content14' /><br />


                <h4><FormattedMessage id='agreement_content2' /></h4>
                <FormattedMessage id='agreement_content21' /><br />
                <FormattedMessage id='agreement_content22' /><br />
                <FormattedMessage id='agreement_content23' /><br />
                

                <h4><FormattedMessage id='agreement_content3' /></h4>
                <FormattedMessage id='agreement_content31' /><br />
                

                <h4><FormattedMessage id='agreement_content4' /></h4>
                <FormattedMessage id='agreement_content41' /><br />
                <FormattedMessage id='agreement_content42' /><br />
                <FormattedMessage id='agreement_content43' /><br />
                <FormattedMessage id='agreement_content44' /><br />


                <h4><FormattedMessage id='agreement_content5' /></h4>
                <FormattedMessage id='agreement_content51' /><br />
                

                <h4><FormattedMessage id='agreement_content6' /></h4>
                <FormattedMessage id='agreement_content61' /><br />

            </div>
        );
    }
}

export default Agreement;
