import React, { Component } from "react";
import "./App.css";
import upgradevip from "./upgradevip_illustration.png"
import bkgnd_bottom from "./app_pagebackground.png"
import wechat_pay from "./upgradevip_wechatpay.png"
import wechat_check from "./app_radiochecked.png"
import appley_apy from "./upgradevip_applepay.png"
import paypal_pay from "./upgradevip_paypalpay.png"
import { FormattedMessage } from 'react-intl'
import Locale from './lang/Locale'
import qs from 'query-string'



const WechatPayGetUrl = 'https://pay1.desktopcal.com/api/pay/list/wechat/app';
const WechatOrderUrl = 'https://pay1.desktopcal.com/api/order/wechat/app';
const PayPalPayGetUrl = 'https://pay.desktopcal.com/api/pay/list/paypal/app';


class Upgrade extends Component {
    state = {
        source: false
    };

    constructor(props) {
        super(props);
        this.state = {

            iOSWeb: false,

            bgColor: "#FF6F09",
            bordercolor1: "#FEF7EF",
            bordercolor2: "#FEF7EF",
            bordercolor3: "#FF5F16",
            curCheck: 3,
            loading: true,

            threeMonthPayAmount: 13,
            oneYearPayAmount: 45,
            threeYearPayAmount: 118,


            c_checksum_ts_1: 0,
            c_checksum_ts_2: 0,
            c_checksum_ts_3: 0,


            c_checksum_val_1: "",
            c_checksum_val_2: "",
            c_checksum_val_3: "",

            pay_days_1: 0,
            pay_days_2: 0,
            pay_days_3: 0,

            applepay: false,
            paypalPay: false,
            wechatPay: false,
            googlePay: false,

            currency: '',
            currencyValue: '',
        };

        this.onPayClick = this.onPayClick.bind(this);
        this.onClickPayItem1 = this.onClickPayItem1.bind(this);
        this.onClickPayItem2 = this.onClickPayItem2.bind(this);
        this.onClickPayItem3 = this.onClickPayItem3.bind(this);
        this.onQueryPayInfoComplete = this.onQueryPayInfoComplete.bind(this);
        this.postData = this.postData.bind(this);
        this.GetRequest = this.GetRequest.bind(this);

        this.renderiOS = this.renderiOS.bind(this);
        this.renderAndroid = this.renderAndroid.bind(this);
    }

    componentDidMount() {
        var u = navigator.userAgent;
        //var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1;
        var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
        this.setState({ iOSWeb: isiOS });


        let payInfo = {};
        let param = qs.parse(this.props.location.search);
        if (param && param.pay) {
            if (param.pay === 'apple' && param.payinfo) {
                let payinfo = decodeURI(param.payinfo);

                if (payinfo) {
                    payinfo.split('|').map(iteminfo => {
                        if (iteminfo) {
                            var iteminfos = new Array();
                            iteminfos = iteminfo.split(':');
                            if (iteminfos) {
                                let payid = iteminfos[0];
                                let paymoney = iteminfos[1];


                                payInfo[payid] = paymoney;
                            }
                        }
                    });
                }
            }

            if (param.pay === 'paypal') {
                this.state.paypalPay = true;
                this.setState({ paypalPay: true });
            }

            if (param.pay === 'wechat') {
                this.state.wechatPay = true;
                this.setState({ wechatPay: true });
            }

            if (param.pay === 'google') {
                this.state.googlePay = true;
                this.setState({ googlePay: true });
            }
        }


        if (param.pay == null && !isiOS) {
            this.state.wechatPay = true;
            this.setState({ wechatPay: true });
        }


        let currency = param.currency;
        this._setCurrentcy(currency);



        let locale = Locale.Instance.getLocal();
        if (this.state.currency.length === 0) {
            if (param && param.lang === 'chs' || locale === 'zh') {
                this.setState({ currency: '¥' });
            }
        }



        if (payInfo['vip3'] && payInfo['vip12'] && payInfo['vip36']) {
            this.state.applepay = true;
            this.state.loading = false;

            this.setState({ applepay: true });
            this.setState({ loading: false });

            this.setState({ iOSWeb: true });
            this.setState({ threeMonthPayAmount: payInfo['vip3'], c_checksum_val_1: 'vip3' });
            this.setState({ oneYearPayAmount: payInfo['vip12'], c_checksum_val_2: 'vip12' });
            this.setState({ threeYearPayAmount: payInfo['vip36'], c_checksum_val_3: 'vip36' });
        }


        let urlParams = this.GetRequest();
        if (urlParams != null) {

            this.paramLang = urlParams['lang'];
            this.paramSource = urlParams['source'];
            this.paramToken = urlParams['token'];
            
            

            let sWechatPayGetUrl = WechatPayGetUrl;
            if( this.paramToken && this.paramToken.length > 0 ){
                sWechatPayGetUrl = `${WechatPayGetUrl}?token=${this.paramToken}`;
            }

            
            if (this.state.wechatPay) {
                fetch(sWechatPayGetUrl)
                    .then(function (response) {
                        return response.json();
                    })
                    .then((myJson) => {
                        console.log(JSON.stringify(myJson));
                        this.onQueryPayInfoComplete(myJson);
                    });
            }


            let sPayPalPayGetUrl = PayPalPayGetUrl;
            if( this.paramToken && this.paramToken.length > 0 ){
                sPayPalPayGetUrl = `${PayPalPayGetUrl}?token=${this.paramToken}`;
            }


            if (this.state.paypalPay) {
                fetch(sPayPalPayGetUrl)
                    .then(function (response) {
                        return response.json();
                    })
                    .then((myJson) => {
                        console.log(JSON.stringify(myJson));
                        this.onQueryPayInfoComplete(myJson);
                    });
            }
        }
    }

    _setCurrentcy(currency) {
        if (currency) {
            currency = currency.toLowerCase();
        }

        if (currency === 'cny') {
            this.setState({ currency: '¥', currencyValue: 'CNY' });
        }
        else if (currency === 'usd') {
            this.setState({ currency: '$', currencyValue: 'USD' });
        }
        else if (currency === 'twd') {
            this.setState({ currency: 'NT$', currencyValue: 'TWD' });
        }
        else if (currency === 'hkd') {
            this.setState({ currency: 'HK$', currencyValue: 'HKD' });
        }
    }

    GetRequest() {
        var url = this.props.location.search; //获取url中"?"符后的字串 
        var theRequest = new Object();
        if (url.indexOf("?") !== -1) {
            var str = url.substr(1);
            var strs = str.split("&");
            for (var i = 0; i < strs.length; i++) {
                theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
            }
        }
        return theRequest;
    }

    postData(url, data, token) {
        // Default options are marked with *

        fetch(url, {
            body: JSON.stringify(data), // must match 'Content-Type' header
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, same-origin, *omit
            headers: {
                'user-agent': 'Mozilla/4.0 MDN Example',
                'content-type': 'application/json',
                'token': token
            },
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, cors, *same-origin
            redirect: 'follow', // manual, *follow, error
            referrer: 'no-referrer', // *client, no-referrer
        })
            .then(response => response.json()) // parses response to JSON
            .then((myJson) => {
                try {
                    let jsonData = JSON.stringify(myJson);
                    console.log(jsonData);


                    window.ReactNativeWebView.postMessage(jsonData);
                }
                catch (e) {
                    alert(e);
                }
            });
    }


    onQueryPayInfoComplete(info) {
        if (info && 0 === info.errorid) {

            let list = info.vdata.list;
            //            alert(JSON.stringify(list));
            let threeMonth = list['90'];
            if (threeMonth) {
                this.setState({ threeMonthPayAmount: threeMonth.pay_amount, c_checksum_ts_1: threeMonth.c_checksum_ts, c_checksum_val_1: threeMonth.c_checksum_val, pay_days_1: threeMonth.pay_days });
                this._setCurrentcy(threeMonth.pay_currency);
            }
            let oneYear = list['365'];
            if (oneYear) {
                this.setState({ oneYearPayAmount: oneYear.pay_amount, c_checksum_ts_2: oneYear.c_checksum_ts, c_checksum_val_2: oneYear.c_checksum_val, pay_days_2: oneYear.pay_days });
            }
            let threeYear = list['1095'];
            if (threeYear) {
                this.setState({ threeYearPayAmount: threeYear.pay_amount, c_checksum_ts_3: threeYear.c_checksum_ts, c_checksum_val_3: threeYear.c_checksum_val, pay_days_3: threeYear.pay_days });
            }
        }
        this.setState({ loading: false });
    }

    onPayClick() {
        console.log("onPayClick");
        this.setState({
            bgColor: "#FFB784"
        });

        let checksum_ts = 0;
        let checksum_val = "";
        let pay_days = 0;
        let payAmount = 0;
        let pay_channel = 'wechat';

        if (1 === this.state.curCheck) {
            checksum_ts = this.state.c_checksum_ts_1;
            checksum_val = this.state.c_checksum_val_1;
            pay_days = this.state.pay_days_1;
            payAmount = this.state.threeMonthPayAmount;
        }
        else if (2 === this.state.curCheck) {
            checksum_ts = this.state.c_checksum_ts_2;
            checksum_val = this.state.c_checksum_val_2;
            pay_days = this.state.pay_days_2;
            payAmount = this.state.oneYearPayAmount;
        }
        else if (3 === this.state.curCheck) {
            checksum_ts = this.state.c_checksum_ts_3;
            checksum_val = this.state.c_checksum_val_3;
            pay_days = this.state.pay_days_3;
            payAmount = this.state.threeYearPayAmount;
        }

        if (this.state.applepay) {
            pay_channel = 'apple';
        }

        if (this.state.paypalPay) {
            pay_channel = 'paypal';
        }

        var data = {
            source: this.paramSource,
            pay_days: pay_days,
            pay_channel: pay_channel,
            c_checksum_ts: checksum_ts,
            c_checksum_val: checksum_val,
            pay_Amount: payAmount,
            currency: this.state.currencyValue,
        };

        console.log(JSON.stringify(data));

        if (pay_channel === 'wechat') {
            this.postData(WechatOrderUrl, data, this.paramToken);
        }
        else if (pay_channel == 'paypal') {
            //alert( JSON.stringify(data) );
            window.ReactNativeWebView.postMessage(JSON.stringify(data));
        }
        else if (pay_channel === 'apple') {
            window.ReactNativeWebView.postMessage(JSON.stringify(data));
        }
    }

    onClickPayItem1() {
        this.setState({ bordercolor1: "#FF5F16", curCheck: 1 });
        this.setState({ bordercolor2: "#FEF7EF" });
        this.setState({ bordercolor3: "#FEF7EF" });
    }

    onClickPayItem2() {
        this.setState({ bordercolor2: "#FF5F16", curCheck: 2 });
        this.setState({ bordercolor1: "#FEF7EF" });
        this.setState({ bordercolor3: "#FEF7EF" });
    }

    onClickPayItem3() {
        this.setState({ bordercolor3: "#FF5F16", curCheck: 3 });
        this.setState({ bordercolor1: "#FEF7EF" });
        this.setState({ bordercolor2: "#FEF7EF" });
    }

    renderiOS() {
        return (

            <div className="App">

                {this.state.loading ?
                    <div></div>
                    :
                    <div>
                        <div className="headerbkgnd">
                            <img src={upgradevip} alt="upgrade logo" className="App-logo" />
                            <div className="upgradeslogn"><FormattedMessage id="upgradeslogn" /></div>
                        </div>
                        <img
                            src={bkgnd_bottom}
                            alt="upgrade bkgnd"
                            className="bkgnd_bottom"
                        />

                        <div className="container">
                            <ol className="textcontainer">
                                <li className="text1"><FormattedMessage id="slogntxt1" /></li>
                                <li className="text1"><FormattedMessage id="slogntxt2" /></li>
                                <li className="text1"><FormattedMessage id="slogntxt3" /></li>
                                <li className="text1"><FormattedMessage id="slogntxt4" /></li>
                                <li className="text1"><FormattedMessage id="slogntxt5" /></li>
                            </ol>

                            {this.state.applepay ?
                                <div>
                                    <div className="payamount">
                                        <div
                                            className="payitem1"
                                            onClick={this.onClickPayItem1}
                                            style={{ borderColor: this.state.bordercolor1 }}
                                        >
                                            <div className="paydate"><FormattedMessage id="threemonth" /></div>
                                            <div className="allmoney">
                                                <div className="payyang">{this.state.currency}</div>
                                                <div className="paymoney">{this.state.threeMonthPayAmount}</div>
                                            </div>
                                        </div>
                                        <div
                                            className="payitem"
                                            onClick={this.onClickPayItem2}
                                            style={{ borderColor: this.state.bordercolor2 }}
                                        >
                                            <div className="oneyear">
                                                <div className="paydate"><FormattedMessage id="oneyear" /></div>
                                            </div>
                                            <div className="allmoney">
                                                <div className="payyang">{this.state.currency}</div>
                                                <div className="paymoney">{this.state.oneYearPayAmount}</div>
                                            </div>
                                        </div>
                                        <div
                                            className="payitem"
                                            onClick={this.onClickPayItem3}
                                            style={{ borderColor: this.state.bordercolor3 }}
                                        >
                                            <div className="oneyear">
                                                <div className="paydate"><FormattedMessage id="threadyears" /></div>
                                            </div>
                                            <div className="allmoney">
                                                <div className="payyang">{this.state.currency}</div>
                                                <div className="paymoney">{this.state.threeYearPayAmount}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="wechatpay">
                                        <img
                                            src={appley_apy}
                                            alt="wechat icon"
                                            className="wechatpayicon"
                                        />
                                        <div className="wechatpaytext"><FormattedMessage id="appleypay" /></div>
                                        <div className="wechatblank" />
                                        <img
                                            src={wechat_check}
                                            alt="rideo check icon"
                                            className="wechatpayicon"
                                        />
                                    </div>

                                    <div
                                        className="paybutton"
                                        onClick={this.onPayClick}
                                        style={{ backgroundColor: this.state.bgColor }}
                                    >
                                        <FormattedMessage id="upgradenow" />
                                    </div>
                                </div>

                                :
                                <div className="payonpc"><FormattedMessage id="iostips" /></div>
                            }
                        </div>
                    </div>
                }
            </div>
        );
    }

    renderAndroid() {
        return (
            <html>
                <body>
                    <div className="App">
                        {this.state.loading ?
                            <div></div>
                            :
                            <div>
                                <div className="headerbkgnd">
                                    <img src={upgradevip} alt="upgrade logo" className="App-logo" />
                                    <div className="upgradeslogn"><FormattedMessage id="upgradeslogn" /></div>
                                </div>
                                <img
                                    src={bkgnd_bottom}
                                    alt="upgrade bkgnd"
                                    className="bkgnd_bottom"
                                />

                                <div className="container">
                                    <ol className="textcontainer">
                                        <li className="text1"><FormattedMessage id="slogntxt1" /></li>
                                        <li className="text1"><FormattedMessage id="slogntxt2" /></li>
                                        <li className="text1"><FormattedMessage id="slogntxt3" /></li>
                                        <li className="text1"><FormattedMessage id="slogntxt4" /></li>
                                        <li className="text1"><FormattedMessage id="slogntxt5" /></li>
                                    </ol>

                                    {this.state.wechatPay || this.state.paypalPay || this.state.googlePay ?
                                        <div className="payamount">
                                            <div
                                                className="payitem1"
                                                onClick={this.onClickPayItem1}
                                                style={{ borderColor: this.state.bordercolor1 }}
                                            >
                                                <div className="paydate"><FormattedMessage id="threemonth" /></div>
                                                <div className="allmoney">
                                                    <div className="payyang">{this.state.currency}</div>
                                                    <div className="paymoney">{this.state.threeMonthPayAmount}</div>
                                                </div>
                                            </div>
                                            <div
                                                className="payitem"
                                                onClick={this.onClickPayItem2}
                                                style={{ borderColor: this.state.bordercolor2 }}
                                            >
                                                <div className="oneyear">
                                                    <div className="paydate"><FormattedMessage id="oneyear" /></div>
                                                    <div className="zhekou"><FormattedMessage id="oneyeardiscount" /></div>
                                                </div>
                                                <div className="allmoney">
                                                    <div className="payyang">{this.state.currency}</div>
                                                    <div className="paymoney">{this.state.oneYearPayAmount}</div>
                                                </div>
                                            </div>
                                            <div
                                                className="payitem"
                                                onClick={this.onClickPayItem3}
                                                style={{ borderColor: this.state.bordercolor3 }}
                                            >
                                                <div className="oneyear">
                                                    <div className="paydate"><FormattedMessage id="threadyears" /></div>
                                                    <div className="zhekou"><FormattedMessage id="threeyearsdiscount" /></div>
                                                </div>
                                                <div className="allmoney">
                                                    <div className="payyang">{this.state.currency}</div>
                                                    <div className="paymoney">{this.state.threeYearPayAmount}</div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="payonpc"><FormattedMessage id="iostips" /></div>
                                    }


                                    {this.state.wechatPay ?
                                        <div>
                                            <div className="wechatpay">
                                                <img
                                                    src={wechat_pay}
                                                    alt="wechat icon"
                                                    className="wechatpayicon"
                                                />
                                                <div className="wechatpaytext"><FormattedMessage id="wechatpay" /></div>
                                                <div className="wechatblank" />
                                                <img
                                                    src={wechat_check}
                                                    alt="rideo check icon"
                                                    className="wechatpayicon"
                                                />
                                            </div>

                                            <div
                                                className="paybutton"
                                                onClick={this.onPayClick}
                                                style={{ backgroundColor: this.state.bgColor }}
                                            >
                                                <FormattedMessage id="upgradenow" />
                                            </div>
                                        </div>
                                        :
                                        null
                                    }

                                    {this.state.paypalPay ?
                                        <div>
                                            <div className="wechatpay">
                                                <img
                                                    src={paypal_pay}
                                                    alt="wechat icon"
                                                    className="wechatpayicon"
                                                />
                                                <div className="wechatpaytext"><FormattedMessage id="paypalpay" /></div>
                                                <div className="wechatblank" />
                                                <img
                                                    src={wechat_check}
                                                    alt="rideo check icon"
                                                    className="wechatpayicon"
                                                />
                                            </div>

                                            <div
                                                className="paybutton"
                                                onClick={this.onPayClick}
                                                style={{ backgroundColor: this.state.bgColor }}
                                            >
                                                <FormattedMessage id="upgradenow" />
                                            </div>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </body>
            </html>
        );
    }

    render() {


        return (
            <div>
                {this.state.iOSWeb ?
                    this.renderiOS()
                    :
                    this.renderAndroid()
                }
            </div>

        );
    }
}

export default Upgrade;
